import fetchData from '@helpers/fetchData';

declare global {
  interface Cookiebot {
    consent: {
      necessary: boolean;
      preferences: boolean;
      statistics: boolean;
      marketing: boolean;
      method: 'implied' | 'explicit' | null;
    };
    consented: boolean;
    declined: boolean;
    hasResponse: boolean;
    doNotTrack: boolean;
    regulations: {
      gdprApplies: boolean;
      ccpaApplies: boolean;
      lgpdApplies: boolean;
    };
    show: () => void;
    hide: () => void;
    renew: () => void;
  }

  interface Window {
    Cookiebot: Cookiebot | HTMLElement;
  }
}

interface Site {
  name: string;
  url: string;
  language: string;
  // The locale for this site in the current site's language, eg. the French locale
  // for an English site would show "French (France)" not "Français (France)"
  displayLocale: string;
  // The language for this site in its own language the the French locale for an
  // English site would show "Français" not "French"
  displayLanguageLocal: string;
}

interface AllSitesResponse {
  sites: Site[];
}

/**
 * Map of language segments from the URL to language codes
 */
const urlMap = [
  { url: 'en', language: 'en-GB' },
  { url: 'ie', language: 'en-IE' },
];

let sitesPromise: Promise<AllSitesResponse> | undefined = undefined;

const logError = (message: string) => {
  console.error(`Error: ${message}`);
};

const findCurrentSite = (allSites: Site[], languageSegment: string) => {
  return allSites.find((site) => site.language === languageSegment);
};

const getAllSites = async () => {
  if (!sitesPromise) {
    sitesPromise = fetchData('/actions/multisite/multisite/get-all-sites');
  }
  const response = await sitesPromise;
  return response.sites;
};

const createSiteListItem = (site: Site) => {
  const listItem = document.createElement('li');
  const svg = document.createElement('img');
  svg.setAttribute('src', `${window.origin}/images/flags/${site.language}.svg`);
  svg.setAttribute('alt', `${site.displayLocale} flag`);
  listItem.appendChild(svg);

  const link = document.createElement('a');
  link.setAttribute('href', site.url);
  link.setAttribute('hreflang', site.language);
  link.setAttribute('aria-label', site.displayLocale);
  if (site.language === 'en-GB') {
    link.textContent = 'UK';
  } else {
    link.textContent = 'Ireland';
  }
  listItem.appendChild(link);

  return listItem;
};

const createSiteList = (sites: Site[]) => {
  const fragment = document.createDocumentFragment();
  sites.forEach((site) => {
    const listItem = createSiteListItem(site);
    fragment.appendChild(listItem);
  });
  return fragment;
};

const getLanguageSegmentFromPath = () => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/').filter((segment) => segment.trim() !== '');
  const pathLanguageSegment = pathSegments[0] || 'en';
  const matchingSite = urlMap.find((site) => site.url === pathLanguageSegment);
  return matchingSite ? matchingSite.language : 'en-GB';
};

const initCurrentSite = async (sites: Site[], currentLanguage: string) => {
  const currentSiteSpan = document.querySelector<HTMLSpanElement>('.js-current-site');

  if (!currentSiteSpan) {
    logError('Current site span not found.');
    return;
  }

  const currentSite = findCurrentSite(sites, currentLanguage);

  if (!currentSite) {
    logError(`No site found for language ${currentLanguage}`);
    return;
  }

  currentSiteSpan.textContent = currentSite.name;
};

const initSiteList = (sites: Site[]) => {
  const countryList = document.querySelector<HTMLUListElement>('.js-country-list');

  if (!countryList) {
    logError('Country list not found.');
    return;
  }

  const siteListFragment = createSiteList(sites);
  countryList.prepend(siteListFragment);
};

const initCookiebot = async (currentLanguage: string) => {
  try {
    const allSites = await getAllSites();
    await initCurrentSite(allSites, currentLanguage);
    initSiteList(allSites);
    // Show the modal now that dom is updated
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.querySelector('.js-modal-cookiebot')!.classList.remove('hidden');
  } catch (error) {
    logError(<string>error);
    return;
  }
};

const init = async () => {
  const currentLanguage = getLanguageSegmentFromPath();
  if (!currentLanguage) {
    logError('No matching site found.');
    return;
  }

  window.addEventListener('CookiebotOnDialogDisplay', function () {
    initCookiebot(currentLanguage);
  });

  // .js-current-site is in the modal added by Cookiebot so will only be present after Cookiebot has
  // loaded
  if (document.querySelector('.js-current-site')) {
    // CookiebotOnDialogDisplay has already run
    initCookiebot(currentLanguage);
  }
};

export default init;
