/**
 * @TODO Fully refactor this file to use the new module loader
 */
import map from '@modules/mapbox';
import navigation from '@modules/navigation';
import cookiebotSiteSelector from '@modules/cookiebotSiteSelector';
import initAnchorLinkScroll from '@modules/anchorLinks';
import { loadModulesWhenInView } from '@helpers/loadModule';

export default function init(): void {
  // Modules
  cookiebotSiteSelector();
  navigation();
  map();
  initAnchorLinkScroll();
  loadModulesWhenInView([
    {
      selector: '.js-accordion-btn',
      importer: () => import('@modules/accordion'),
    },
    {
      selector: '.js-social-share-button',
      importer: () => import('@modules/socialShare'),
    },
    {
      selector: '.js-footer-skip-link',
      importer: () => import('@modules/footerSkipLink'),
    },
    {
      selector: '.js-swiper',
      importer: () => import('@modules/swiper'),
    },
  ]);
}
