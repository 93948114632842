// Packages
import { createApp } from 'vue';

// Modules
import getMedia from '@helpers/breakpoints';

// Vue components
import HeaderSearch from '@vue-components/HeaderSearch.vue';

const isDesktopMedia: MediaQueryList = getMedia('lg');

export default function init(): void {
  const header = document.querySelector<HTMLElement>('#js-header')!;

  // Create Vue instance for header
  const vueInstance = createApp({
    name: 'HeaderInstance',
    delimiters: ['${', '}'],
    components: {
      HeaderSearch,
    },
    data() {
      return {
        isDesktop: isDesktopMedia.matches,
      };
    },
    created() {
      // Make isMobile responsive
      isDesktopMedia.addEventListener
        ? isDesktopMedia.addEventListener('change', this.isDesktopMediaListener)
        : isDesktopMedia.addListener(this.isDesktopMediaListener);
    },
    beforeUnmount() {
      isDesktopMedia.removeEventListener
        ? isDesktopMedia.removeEventListener('change', this.isDesktopMediaListener)
        : isDesktopMedia.removeListener(this.isDesktopMediaListener);
    },
    methods: {
      isDesktopMediaListener(media: MediaQueryListEvent) {
        this.isDesktop = media.matches;
      },
      showCookiebot() {
        if (window.Cookiebot) {
          window.Cookiebot.show();
        }
      },
    },
  });

  // Mount the app
  vueInstance.mount(header);
}
